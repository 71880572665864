<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("bill.new") }}</TitleBar>
        <TitleBar
            v-else
            :disabled="loading"
            :loading="loading"
            :menu="menu"
        >
            {{ $t("bill.edit") }}
        </TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">
            <v-row>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        v-model="contact"
                        :disabled="loading || this.$route.params.uuid !== 'new'"
                        :error-messages="contactErrors"
                        :items="contacts"
                        :label="$t('bill.contact')"
                        :no-data-text="$t('no_data')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.contact.$touch()"
                        @input="$v.contact.$touch()"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="category"
                        :disabled="loading"
                        :error-messages="categoryErrors"
                        :items="categories"
                        :label="$t('bill.category')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.category.$touch()"
                        @input="$v.category.$touch()"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="currency"
                        :disabled="loading"
                        :error-messages="currencyErrors"
                        :items="currencies"
                        :label="$t('bill.currency')"
                        outlined
                        @blur="$v.currency.$touch()"
                        @input="$v.currency.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <DatePicker
                        v-model="emitted"
                        :disabled="loading"
                        :error-messages="emittedErrors"
                        :label="$t('bill.emitted')"
                        @blur="$v.emitted.$touch()"
                        @input="$v.emitted.$touch()"
                    ></DatePicker>
                </v-col>
                <v-col cols="12" md="4">
                    <DatePicker
                        v-model="due_date"
                        :disabled="loading"
                        :error-messages="dueDateErrors"
                        :label="$t('bill.due_date')"
                        @blur="$v.due_date.$touch()"
                        @input="$v.due_date.$touch()"
                    ></DatePicker>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="bill_number"
                        :disabled="loading"
                        :error-messages="billNumberErrors"
                        :label="$t('bill.bill_number')"
                        outlined
                        @blur="$v.bill_number.$touch()"
                        @input="$v.bill_number.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="order_number"
                        :disabled="loading"
                        :label="$t('bill.order_number')"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                        v-model="status"
                        :disabled="loading"
                        :items="statuses"
                        :label="$t('bill.status')"
                        outlined
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-textarea
                        v-model="notes"
                        :label="$t('bill.notes')"
                        auto-grow
                        outlined
                        rows="3"
                    ></v-textarea>
                </v-col>
            </v-row>

            <Items
                v-model="items"
                :disabled="loading"
                :label="$t('bill.items')"
            >
            </Items>

            <!--          Self billing-->
            <v-switch
                v-model="selfbilling"
                :label="$t('bill.selfbilling')"
                color="secondary"
            ></v-switch>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("bill.add") : $t("bill.update") }}
            </v-btn>
        </v-form>


        <!--        Fields -->

        <Attachments v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Attachments>

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Currencies from "../../helpers/Currencies";
import DatePicker from "../../components/DatePicker";
import Items from "../../components/Items";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";
import Statuses from "@/helpers/Statuses";
import DynamicElement from "@/helpers/DynamicElement";

export default {
    name: "Details",
    components: {TitleBar, Items, DatePicker, Attachments, Tags},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.bills"),
                to: "/expenses/bills",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("bill.new") : this.$t("bill.edit"),
                to: "/expenses/bills/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.statuses = Statuses.get(this.$i18n.locale, ["denied"]);
        // 'draft', 'sent', 'viewed', 'approved', 'partial', 'paid', 'received', 'cancelled', 'voided'

        this.loading = true;
        if (this.$route.params.uuid === "new") {
            let contacts = await Accounting.get("/contacts?fields=name,uuid&per_page=-1&page=1").catch(() => {
            });
            this.contacts = contacts.data.contacts;
        }

        let categories = await Accounting.get("/categories?fields=name,uuid&per_page=-1&page=1").catch(() => {
        });
        this.categories = categories.data.categories;

        this.loading = false;

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            this.menu.push({text: this.$t("bill.delete"), callback: this.deleteElement, class: "red--text"});
            Accounting.get("/expenses/bills/" + this.$route.params.uuid)
                .then(async response => {
                    const data = response.data;
                    this.items = data.items;
                    this.contact = data.contact;
                    this.currency = data.currency;
                    this.emitted = data.emitted;
                    this.due_date = data.due_date;
                    this.bill_number = data.bill_number;
                    this.order_number = data.order_number;
                    this.notes = data.notes;
                    this.category = data.category;
                    this.status = data.status;
                    this.selfbilling = data.selfbilling;

                    this.contacts = [{
                        name: await DynamicElement.cache("contact", DynamicElement.getCustomer, this.contact),
                        uuid: this.contact
                    }];

                    if (this.selfbilling) {
                        this.menu.push({text: this.$t("bill.download"), callback: this.downloadBill, class: ""});
                    }
                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }
    },
    data: () => {
        return {
            loading: false,
            currencies: Currencies.get(),
            categories: [],
            // statuses: ['draft', 'sent', 'viewed', 'approved', 'partial', 'paid', 'received', 'cancelled', 'voided'],
            statuses: [],
            contacts: [],
            contact: null,
            category: null,
            currency: null,
            due_date: null,
            items: [],
            notes: null,
            order_number: null,
            emitted: null,
            bill_number: null,
            status: null,
            selfbilling: false,
            menu: []
        };
    },
    methods: {
        downloadBill() {
            this.loading = true;
            Accounting.blob("/expenses/bills/" + this.$route.params.uuid + "/document")
                .then(blob => {
                    let object = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = object;
                    link.download = this.bill_number;
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.dispatchEvent(
                        new MouseEvent("click", {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        })
                    );
                    document.body.removeChild(link);
                    this.loading = false;
                }).catch(() => {
                this.loading = false;
            });
        },
        deleteElement() {
            this.$swal({
                title: this.$t("bill.delete_title"),
                text: this.$t("bill.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("bill.delete_yes"),
                cancelButtonText: this.$t("bill.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/expenses/bills/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("bill.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/expenses/bills");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/expenses/bills";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    category: this.category,
                    contact: this.contact,
                    currency: this.currency,
                    due_date: this.due_date,
                    items: this.items,
                    notes: this.notes,
                    order_number: this.order_number,
                    emitted: this.emitted,
                    bill_number: this.bill_number,
                    status: this.status,
                    selfbilling: this.selfbilling
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("bill.added")
                        }]);
                        this.$router.push("/expenses/bills/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("bill.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        contact: {required},
        currency: {required},
        category: {required},
        due_date: {required},
        emitted: {required},
        bill_number: {required}
    },
    computed: {
        contactErrors() {
            const errors = [];
            if (!this.$v.contact.$dirty) return errors;
            !this.$v.contact.required && errors.push(this.$t("bill.contact_required"));
            return errors;
        },
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency.$dirty) return errors;
            !this.$v.currency.required && errors.push(this.$t("bill.currency_required"));
            return errors;
        },
        categoryErrors() {
            const errors = [];
            if (!this.$v.category.$dirty) return errors;
            !this.$v.category.required && errors.push(this.$t("bill.category_required"));
            return errors;
        },
        dueDateErrors() {
            const errors = [];
            if (!this.$v.due_date.$dirty) return errors;
            !this.$v.due_date.required && errors.push(this.$t("bill.due_date_required"));
            return errors;
        },
        emittedErrors() {
            const errors = [];
            if (!this.$v.emitted.$dirty) return errors;
            !this.$v.emitted.required && errors.push(this.$t("bill.emitted_required"));
            return errors;
        },
        billNumberErrors() {
            const errors = [];
            if (!this.$v.bill_number.$dirty) return errors;
            !this.$v.bill_number.required && errors.push(this.$t("bill.bill_number_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>